import { render, staticRenderFns } from "./TwoFactorWizardNotScannable.vue?vue&type=template&id=1b9720f5&scoped=true"
import script from "./TwoFactorWizardNotScannable.vue?vue&type=script&lang=js"
export * from "./TwoFactorWizardNotScannable.vue?vue&type=script&lang=js"
import style0 from "./TwoFactorWizardNotScannable.vue?vue&type=style&index=0&id=1b9720f5&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b9720f5",
  null
  
)

export default component.exports