<template>
  <two-factor-wizard-skeleton>
    <template #header>{{ $t('twoFactor.notScannable.title') }}</template>

    <ul class="list-decimal list-inside setup-list">
      <li>
        <i18n path="twoFactor.notScannable.firstPoint" :tag="false">
          <template #menu>
            <b>{{ $t('twoFactor.notScannable.menu') }}</b>
          </template>
          <template #createAccount>
            <b>{{ $t('twoFactor.notScannable.createAccount') }}</b>
          </template>
        </i18n>
      </li>
      <li>
        <i18n path="twoFactor.notScannable.secondPoint" :tag="false">
          <template #insertKey>
            <b>{{ $t('twoFactor.notScannable.insertKey') }}</b>
          </template>
        </i18n>
      </li>
      <li>
        {{ $t('twoFactor.notScannable.thirdPoint') }}
        <p class="mt-8">
          <b>{{ formattedSecret }}</b>
        </p>
        <p>{{ $t('twoFactor.notScannable.noSpaces') }}</p>
      </li>
      <li class="mt-8">
        <i18n path="twoFactor.notScannable.fourthPoint" :tag="false">
          <template #timeBased>
            <b>{{ $t('twoFactor.notScannable.timeBased') }}</b>
          </template>
        </i18n>
      </li>
      <li>
        <i18n path="twoFactor.notScannable.fifthPoint" :tag="false">
          <template #add>
            <b>{{ $t('twoFactor.notScannable.add') }}</b>
          </template>
        </i18n>
      </li>
    </ul>

    <template #buttons>
      <button class="btn btn--primary" @click="goNext">
        {{ $t('twoFactor.setup.next') }}
      </button>
    </template>
  </two-factor-wizard-skeleton>
</template>

<script>
import TwoFactorWizardSkeleton from '@/components/twofactor/TwoFactorWizardSkeleton.vue';

export default {
  components: {
    TwoFactorWizardSkeleton,
  },
  props: {
    secret: {
      type: String,
      required: true,
    },
  },
  computed: {
    formattedSecret() {
      // Lets format the secret a little bit to make it more readable
      return this.secret.match(/.{1,4}/g).join(' ');
    },
  },
  methods: {
    goNext() {
      this.$emit('next');
    },
  },
};
</script>

<style lang="postcss" scoped>
.setup-list {
  & li {
    @apply py-1;
  }
}
</style>
