<template>
  <two-factor-wizard-skeleton>
    <template #header>{{ $t('twoFactor.setup.title') }}</template>
    <b>{{ $t('twoFactor.setup.security') }}</b>
    <span class="block">{{ $t('twoFactor.setup.securityHint') }}</span>
    <ol class="mb-10">
      <li>{{ $t('twoFactor.setup.securityStepOne') }}</li>
      <li>{{ $t('twoFactor.setup.securityStepTwo') }}</li>
    </ol>
    <b>{{ $t('twoFactor.setup.downloadApp') }}</b>
    <ol class="mb-10 whitespace-pre-line list-decimal list-inside setup-list">
      <li>
        <i18n path="twoFactor.setup.firstPoint" :tag="false">
          <template #appStore>
            <a
              href="https://apps.apple.com/ch/app/google-authenticator/id388497605"
              target="_blank"
              >{{ $t('twoFactor.setup.appStore') }}</a
            >
          </template>
          <template #googleStore>
            <a
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
              target="_blank"
              >{{ $t('twoFactor.setup.googleStore') }}</a
            >
          </template>
        </i18n>
      </li>
      <li>
        {{ $t('twoFactor.setup.secondPoint') }}
      </li>
      <li>
        {{ $t('twoFactor.setup.thirdPoint') }}
      </li>
    </ol>
    <canvas id="qr"></canvas>
    <a class="cursor-pointer" @click="goToNotScannable">
      {{ $t('twoFactor.setup.codeNotScannable') }}
    </a>

    <template #buttons>
      <button class="btn btn--primary" @click="goNext">
        {{ $t('twoFactor.setup.next') }}
      </button>
    </template>
  </two-factor-wizard-skeleton>
</template>

<script>
import TwoFactorWizardSkeleton from '@/components/twofactor/TwoFactorWizardSkeleton.vue';
import QRious from 'qrious';

export default {
  components: {
    TwoFactorWizardSkeleton,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  mounted() {
    new QRious({
      element: document.getElementById('qr'),
      value: this.url,
      size: 160,
    });
  },
  methods: {
    goNext() {
      this.$emit('next');
    },
    goToNotScannable() {
      this.$emit('notScannable');
    },
  },
};
</script>

<style lang="postcss" scoped>
/deep/ ol {
  list-style: disc;
  & li {
    @apply ml-6;
  }
}
</style>
