<template>
  <div class="h-full">
    <two-factor-wizard-setup
      v-if="step == 0"
      :url="url"
      @next="goToVerify"
      @notScannable="goToNotScannable"
    />
    <two-factor-wizard-not-scannable
      v-if="step == 1"
      :secret="secret"
      @next="goToVerify"
    />
    <two-factor-wizard-verify
      v-if="step == 2"
      :wrong-token="wrongToken"
      @next="activate2FA"
    />
  </div>
</template>

<script>
import { activate2FA } from '@/services/twofactor';
import TwoFactorWizardSetup from '@/components/twofactor/TwoFactorWizardSetup.vue';
import TwoFactorWizardVerify from '@/components/twofactor/TwoFactorWizardVerify.vue';
import TwoFactorWizardNotScannable from '@/components/twofactor/TwoFactorWizardNotScannable.vue';

export default {
  components: {
    TwoFactorWizardSetup,
    TwoFactorWizardVerify,
    TwoFactorWizardNotScannable,
  },
  props: {
    settings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      step: 0,
      url: '',
      secret: '',
      wrongToken: false,
    };
  },
  created() {
    this.url = this.settings.url;
    this.secret = this.settings.secret;
  },
  methods: {
    goToVerify() {
      this.step = 2;
    },
    goToNotScannable() {
      this.step = 1;
    },
    async activate2FA(code) {
      try {
        await activate2FA({
          code,
          secret: this.secret,
          email: this.settings.user,
        });
        this.$router.go();
      } catch (e) {
        if (e.response.status === 400) {
          this.wrongToken = true;
        } else {
          this.$notify({
            type: 'error',
            text:
              e.response?.data?.message ||
              this.$t('notifications.activate2FAError').toString(),
          });
        }
      }
    },
    closeWizard() {
      this.$emit('close');
    },
  },
};
</script>
