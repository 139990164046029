<template>
  <div class="flex flex-col justify-between h-full">
    <div class="mb-10">
      <h2 class="mb-10 text-4xl leading-normal"><slot name="header" /></h2>
      <div><slot /></div>
    </div>

    <div class="flex justify-between mb-10 wizard-buttons">
      <slot name="buttons"></slot>
    </div>
    <a class="block cursor-pointer" @click="backToLogin">
      {{ $t('login.backToLogin') }}
    </a>
  </div>
</template>

<script>
export default {
  methods: {
    backToLogin() {
      this.$router.go();
    },
  },
};
</script>

<style lang="postcss">
.wizard-buttons {
  & button {
    min-width: 175px;
    min-height: 50px;
  }
}
</style>
