<template>
  <two-factor-wizard-skeleton>
    <template #header>
      {{ $t('twoFactor.verify.title') }}
    </template>

    <p class="mb-10 whitespace-pre-line">
      {{ $t('twoFactor.verify.text') }}
    </p>

    <label-field :label="$t('twoFactor.verify.codeLabel')" is-block>
      <input v-model="code" v-focus class="w-full form-input" />
    </label-field>

    <p v-if="wrongToken" class="mt-2 text-primary-500">
      {{ $t('twoFactor.verify.wrongToken') }}
    </p>

    <template #buttons>
      <button class="btn btn--primary" @click="goNext">
        {{ $t('twoFactor.verify.activate') }}
      </button>
    </template>
  </two-factor-wizard-skeleton>
</template>

<script>
import TwoFactorWizardSkeleton from '@/components/twofactor/TwoFactorWizardSkeleton.vue';
import LabelField from '@/components/LabelField.vue';

export default {
  components: {
    TwoFactorWizardSkeleton,
    LabelField,
  },
  props: {
    wrongToken: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      code: '',
    };
  },
  methods: {
    goNext() {
      this.$emit('next', this.code);
    },
  },
};
</script>
