import { userApi } from './api';

/**
 * Activate the two factor authentication
 */
const activate2FA = async (payload) => {
  const response = await userApi.post(`account/activate-2fa/`, payload);

  return response.data;
};

/**
 * Deactivate the two factor authentication
 */
const deactivate2FA = async () => {
  const response = await userApi.post(`account/deactivate-2fa/`);

  return response.data;
};

export { activate2FA, deactivate2FA };
